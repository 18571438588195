export enum TRACKING_EVENTS_NAME {
  USER_CLICK_FAVORITED_ACCOUNT = "user_click_favorited_account",
  USER_CLICK_SAVE_PROFILE = "user_click_save_profile",
  USER_OPEN_DIRECT_MESSAGE = "user_open_direct_message",
  USER_CLICK_PROFILE_CARD = "user_click_profile_card",
  USER_UPDATE_COVER_PHOTO = "user_update_cover_photo",
  USER_CLICK_PRODUCT_CHECKOUT = "user_click_product_checkout",
  USER_CLICK_PLAY_PROJECT = "user_click_play_project",
  USER_CLICK_SHARE_RELEASE = "user_click_share_release",
  USER_CLICK_OPEN_ACCOUNT_SETTINGS = "user_click_open_account_settings",
  USER_CLICK_SOCIAL_MEDIA_LINK = "user_click_social_media_link",
  USER_CLICK_EDIT_SOCIAL_MEDIA_LINKS = "user_click_edit_social_media_links",
  USER_CLICK_GENERATE_BOOKING = "user_click_generate_booking",
  USER_CLICK_BOOK_NOW = "user_click_book_now_click",
  USER_GENERATING_TRANSACTION = "user_generating_transaction",
  USER_CLICK_ADD_OR_EDIT_SERVICE = "user_click_add_or_edit_service",
  USER_CLICK_PAYWALL_OPTION = "user_click_paywall_option",
  USER_CLICK_SAVE_ASSET = "user_click_save_asset",
  USER_CLICK_THUMBNAIL_ASSET = "user_click_thumbnail_asset",
  USER_CLICK_CANCEL_ASSET = "user_click_cancel_asset",
  SYSTEM_SUBMIT_FORM = "system_submit_form",
  USER_CLOSE_DIALOG = "user_close_dialog",
  USER_VISIT_PROFILE = "user_visit_profile",
  LEAVE_404_PAGE = "leave-404-page",
  OPEN_UMG_LOGIN = "open_umg_login",
  MARK_PAID_REVISION = "mark_paid_revision",
  MARK_PAID_SESSION_EXTENSION = "mark_paid_session_extension",
  MARK_PAID_SCHEDULED_PROJECT = "mark_paid_scheduled_project",
  USER_SEARCH = "user_search",
  STUDIO_ROOM_SEARCH = "studio_room_search",
  USER_SEARCH_QUICK_FILTER_SELECTION = "search_quick_filter_selection",
  APPLY_SEARCH_FILTERS = "apply_search_filters",
  CLICK_TRENDING_ENGINEERS_GET_STARTED = "clicked_trending_engineers_get_started",
  CLICK_TRENDING_STUDIO_ROOMS_JOIN_NOW = "clicked_trending_studio_rooms_join_now",
  CLICK_HOME_POPULAR_SEARCH_TRENDING_ENGINEERS = "homepage_popular_search_trending_engineers_click",
  CLICK_HOME_POPULAR_SEARCH_STUDIOS = "homepage_popular_search_studios_click",
  CLICK_HOME_POPULAR_SEARCH_DOLBY_ATMOS = "homepage_popular_search_dolby_atmos_click",
  CLICK_HOME_POPULAR_SERVICE_RECORDING_STUDIOS = "homepage_popular_service_recording_studios_click",
  CLICK_HOME_POPULAR_SERVICE_RECORDING_ENGINEERS = "homepage_popular_service_recording_engineers_click",
  CLICK_HOME_POPULAR_SERVICE_MIXING_ENGINEERS = "homepage_popular_service_mixing_engineers_click",
  CLICK_HOME_POPULAR_SERVICE_MASTERING_ENGINEERS = "homepage_popular_service_mastering_engineers_click",
  CLICK_HOME_POPULAR_SERVICE_DOLBY_ATMOS_ENGINEERS = "homepage_popular_service_atmos_engineers_click",
  CLICK_HOME_POPULAR_SERVICE_MADE_ON_ENGINEEARS = "homepage_popular_service_made_on_engineears_click",
  CLICK_HOME_PERSONA_PAGE_LINK = "clicked_homepage_persona_page_link",
  CLICK_HOME_TRENDING_ENGINEERS_VIEW_MORE = "clicked_homepage_trending_engineers_view_more",
  CLICK_HOME_TRENDING_STUDIO_ROOMS_VIEW_MORE = "clicked_homepage_trending_studio_rooms_view_more",
  CLICK_HOME_PLATINUM_LEARN_MORE = "clicked_homepage_platinum_learn_more",
  CLICK_HOME_PLATINUM_GET_STARTED = "clicked_home_platinum_get_started",
  CLICK_HOME_MADE_ON_ENGINEEARS_GET_STARTED = "clicked_home_made_on_engineears_get_started",
  CLICK_HOME_MADE_ON_ENGINEEARS_FIND_MY_ENGINEER = "clicked_home_made_on_engineers_find_my_engineer",
  CLICK_HOME_MADE_ON_ENGINEEARS_SCHEDULE_CONSULTATION = "landing_screen_open_artist_calendly_link",
  CLICK_HOME_MADE_ON_ENGINEEARS_TRACK_CARD = "clicked_home_made_on_engineers_track_card",
  USER_CLICKED_EMPTY_RELEASES_CTA = "user_clicked_empty_releases_cta",
  USER_CLICKED_VIEW_CAMPAIGNS = "user_clicked_view_campaigns",
  USER_CLICKED_CREATE_CAMPAIGN = "user_clicked_create_campaign",
  USER_CLICK_UPDATE_BOOKING_LINK = "user_click_update_booking_link",
  USER_CLICK_GO_TO_OVERVIEW_PAGE = "user_click_go_to_overview_page",
  USER_CLICK_COPY_BOOKING_LINK = "user_click_copy_booking_link",
  USER_CLICK_COPY_SCHEDULED_PROJECT_SHARE_LINK = "user_click_copy_scheduled_project_share_link",
  USER_CLICK_TOGGLE_BOOKING_LINK_TYPE = "user_click_toggle_booking_link_type",
  PERFORMANCE_CHART_SELECT_ALL_STUDIOS = "performance_chart_select_all_studios",
  PERFORMANCE_CHART_SELECT_A_STUDIOS = "performance_chart_select_a_studios",
  PERFORMANCE_CHART_OPEN_SIDE_PANEL = "performance_chart_open_side_panel",
  PERFORMANCE_CHART_FILTER_CHANGE = "performance_chart_filter_change",
  PERFORMANCE_CHART_SET_ADD_A_STUDIO = "performance_chart_set_add_a_studio",
  PERFORMANCE_CHART_FILTER_CHANGE_DATE_RANGE_MOBILE = "performance_chart_filter_change_date_range_mobile",
  PERFORMANCE_CHART_FILTER_START_DATE = "performance_chart_filter_start_date",
  PERFORMANCE_CHART_FILTER_END_DATE = "performance_chart_filter_end_date",
  PERFORMANCE_CHART_FILTER_SET_ENGINEER_FILTER = "performance_chart_filter_SET_ENGINEER_FILTER",
  ENGINEER_TOGGLE_BOOKING = "engineer_toggle_booking",
  MIGRATE_ANONYMOUS_ID = "migrate_anonymous_id",
  REGISTER_VIA_OTP = "register_via_otp",
  REGISTER_VIA_EMAIL = "register_via_email",
  REGISTER_VIA_GOOGLE = "register_via_google",
  REGISTER_VIA_WHITELIST = "register_via_whitelist",
  CLICK_DASHBOARD_CARD = "clicked_dashboard_card",
  CLICK_COPY_IN_PROGRESS_LINK = "click_copy_in_progress_link",
  CLICK_EMAIL_IN_PROGRESS_LINK = "click_email_in_progress_link",
  REGISTER_AS_ARTIST = "register_as_artist",
  REGISTER_AS_ENGINEER = "register_as_engineer",
  REGISTER_AS_STUDIO_MANAGER = "register_as_studio_manager",
  REGISTER_AS_PRODUCER = "register_as_producer",
  REGISTER_AS_LISTENER = "register_as_listener",
  REGISTER_AS_OTHER = "register_as_other",
  REGISTER_AS_LABEL_ADMIN = "register_as_label_admin",
  REGISTER_AS_LABEL_AR = "register_as_label_ar",
  ACCOUNT_TYPE_UPDATE = "account_type_update",
  CLICK_ENGINEEARS_SYMPHONY_PARTNER_LINK = "click_engineears_symphony_partner_link",
  CLICK_ACCEPT_SESSION = "on_click_accept_session",
  USER_CLICK_NAV_MENU_ITEM = "user_click_nav_menu_item",
  USER_CLICK_NAV_MENU_ITEM_VIDEO_LINK = "user_click_nav_menu_item_video_link",
  USER_CLICKED_DROP_DOWN_MENU_ITEM = "user_clicked_drop_down_menu_item_on_mobile",
  USER_CLICKED_CREATE_CAMPAIGN_BUTTON = "user_clicked_create_campaign_button",
  USER_CLICKED_BACK_FROM_CAMPAIGN_DETAILS = "user_clicked_back_from_campaign_details",
  USER_CLICKED_DISMISSED_CAMPAIGN_SIDE_PANEL = "user_dismissed_campaign_side_panel",
  USER_CLICKED_VIEW_CAMPAIGN_DETAILS = "user_clicked_view_campaign_details",
  USER_CLICKED_CAMPAIGN_CHECKOUT_BUTTON = "user_clicked_campaign_checkout_button",
  USER_SUBMITTED_CAMPAIGN_CHECKOUT = "user_submitted_campaign_checkout",
  USER_CAMPAIGN_CHECKOUT_SUCCESS = "user_campaign_checkout_success",
  USER_CAMPAIGN_CHECKOUT_FAILED = "user_campaign_checkout_failed",
  USER_CLICKED_CAMPAIGN_SUCCESS_DISMISS_BUTTON = "user_clicked_campaign_success_dismiss_button",
}
