import { useLocalStorageState } from "ahooks";
import { LOCAL_STORAGE_KEYS } from "../../constants/localstorageConstants";

export const useRealTmeInteractedUserIds = () => {
  const [realTimeInteractedUserIds, setRealTimeInteractedUserIds] =
    useLocalStorageState<number[]>(
      LOCAL_STORAGE_KEYS.REAL_TIME_INTERACTION_USER_IDS,
      { defaultValue: [] },
    );

  const updateRealTimeInteractedUserIds = (userId: number) => {
    setRealTimeInteractedUserIds((prev) => {
      const updatedList = [userId, ...(prev || [])];
      return updatedList.slice(0, 6);
    });
  };

  return { realTimeInteractedUserIds, updateRealTimeInteractedUserIds };
};
