import { OptionType } from "../../stories/elements/DropDownSelector/DropdownSelector";
import { SelectAMPMOptions, SelectHourInDayOptions } from "./alts";
import User from "./user";

export type WeekdayTime = {
  start: OptionType<number>;
  end: OptionType<number>;
  startAMPM: OptionType<number>;
  endAMPM: OptionType<number>;
};

export enum WEEKDAYS {
  MONDAY = 0,
  TUESDAY = 1,
  WEDNESDAY = 2,
  THURSDAY = 3,
  FRIDAY = 4,
  SATURDAY = 5,
  SUNDAY = 6,
}

export const weekdaysToStringMap = new Map<WEEKDAYS, string>([
  [WEEKDAYS.MONDAY, "Monday"],
  [WEEKDAYS.TUESDAY, "Tuesday"],
  [WEEKDAYS.WEDNESDAY, "Wednesday"],
  [WEEKDAYS.THURSDAY, "Thursday"],
  [WEEKDAYS.FRIDAY, "Friday"],
  [WEEKDAYS.SATURDAY, "Saturday"],
  [WEEKDAYS.SUNDAY, "Sunday"],
]);

export enum DateObjectWeekdays {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export const mapDateObjectWeekdaysToWeekdays = (day: number) => {
  switch (day) {
    case DateObjectWeekdays.SUNDAY:
      return WEEKDAYS.SUNDAY;
    case DateObjectWeekdays.MONDAY:
      return WEEKDAYS.MONDAY;
    case DateObjectWeekdays.TUESDAY:
      return WEEKDAYS.TUESDAY;
    case DateObjectWeekdays.WEDNESDAY:
      return WEEKDAYS.WEDNESDAY;
    case DateObjectWeekdays.THURSDAY:
      return WEEKDAYS.THURSDAY;
    case DateObjectWeekdays.FRIDAY:
      return WEEKDAYS.FRIDAY;
    case DateObjectWeekdays.SATURDAY:
      return WEEKDAYS.SATURDAY;
    default:
      return WEEKDAYS.SUNDAY;
  }
};

export interface WorkingHours {
  availability: string;
  day_of_week: WEEKDAYS;
  user: null | User;
  studio_room: null | number;
}

export const NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY = 96;
export const NUMBER_OF_FIFTEEN_MINUTE_NOTCHES_IN_A_DAY = 97;
export const FIFTEEN_MINUTE_INCREMENT_AVAILABLE = 1;
export const FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE = 0;
export const AM = 0;
export const PM = 1;
export const INVALID_AM_PM = -1;

export const convertTimeToHour = (time: number, ampm: number) => {
  if (ampm === AM) {
    return time;
  } else if (ampm === PM) {
    return time + 12;
  }
  return INVALID_AM_PM;
};

const convertHourToIndex = (hour: number) => {
  return hour * 4;
};

export const fillInUnavailability = (startTime: number, endTime: number) => {
  const binaryArray = new Array(
    NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY,
  ).fill(FIFTEEN_MINUTE_INCREMENT_AVAILABLE);
  const startIndex = convertHourToIndex(startTime);
  const endIndex = convertHourToIndex(endTime);

  for (let i = 0; i < startIndex; i++) {
    binaryArray[i] = FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE;
  }
  for (
    let i = endIndex;
    i < NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY;
    i++
  ) {
    binaryArray[i] = FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE;
  }
  return binaryArray as number[];
};

export const fillInAvailability = (startTime: number, endTime: number) => {
  const binaryArray = new Array(
    NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY,
  ).fill(FIFTEEN_MINUTE_INCREMENT_AVAILABLE);
  const startIndex = convertHourToIndex(startTime);
  const endIndex = convertHourToIndex(endTime);

  for (let i = startIndex; i < endIndex; i++) {
    binaryArray[i] = FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE;
  }
  return binaryArray as number[];
};

export const convertBinaryArrayToBinaryString = (binaryArray: number[]) => {
  let binaryString = "";

  for (let i = 0; i < binaryArray.length; i++) {
    binaryString += binaryArray[i];
  }
  return binaryString;
};

export const combineAvailabilities = (
  availability1: string,
  availability2: string,
): string => {
  let result = "";
  if (availability1.length !== availability2.length) {
    return result;
  }
  for (let i = 0; i < availability1.length; i++) {
    if (availability1[i] === "1" || availability2[i] === "1") {
      result += "1";
    } else {
      result += "0";
    }
  }
  return result;
};

export const parseWeekdays = (workingHours: WorkingHours[]) => {
  return workingHours
    .filter((weekday) => weekday.availability.includes("1"))
    .map((weekday) => weekday.day_of_week);
};

export const getDefaultWeekdayTimes = () => {
  return {
    0: {
      start: SelectHourInDayOptions[0],
      end: SelectHourInDayOptions[0],
      startAMPM: SelectAMPMOptions[0],
      endAMPM: SelectAMPMOptions[0],
    },
    1: {
      start: SelectHourInDayOptions[0],
      end: SelectHourInDayOptions[0],
      startAMPM: SelectAMPMOptions[0],
      endAMPM: SelectAMPMOptions[0],
    },
    2: {
      start: SelectHourInDayOptions[0],
      end: SelectHourInDayOptions[0],
      startAMPM: SelectAMPMOptions[0],
      endAMPM: SelectAMPMOptions[0],
    },
    3: {
      start: SelectHourInDayOptions[0],
      end: SelectHourInDayOptions[0],
      startAMPM: SelectAMPMOptions[0],
      endAMPM: SelectAMPMOptions[0],
    },
    4: {
      start: SelectHourInDayOptions[0],
      end: SelectHourInDayOptions[0],
      startAMPM: SelectAMPMOptions[0],
      endAMPM: SelectAMPMOptions[0],
    },
    5: {
      start: SelectHourInDayOptions[0],
      end: SelectHourInDayOptions[0],
      startAMPM: SelectAMPMOptions[0],
      endAMPM: SelectAMPMOptions[0],
    },
    6: {
      start: SelectHourInDayOptions[0],
      end: SelectHourInDayOptions[0],
      startAMPM: SelectAMPMOptions[0],
      endAMPM: SelectAMPMOptions[0],
    },
  };
};

export const convertAvailabilityToTimes = (availability: string) => {
  const startIndex = availability.indexOf("1");
  const endIndex = availability.lastIndexOf("1") + 1;

  // Case when no availability for the day
  if (startIndex === -1) {
    return {
      start: SelectHourInDayOptions[0],
      end: SelectHourInDayOptions[0],
      startAMPM: SelectAMPMOptions[0],
      endAMPM: SelectAMPMOptions[0],
    };
  }

  const startHour = Math.floor(startIndex / 4);
  const startMinute = (startIndex % 4) * 15;
  const endHour = Math.floor(endIndex / 4);
  const endMinute = (endIndex % 4) * 15;

  const startAMPM =
    startHour >= 12 ? SelectAMPMOptions[1] : SelectAMPMOptions[0];
  const endAMPM = endHour >= 12 ? SelectAMPMOptions[1] : SelectAMPMOptions[0];

  return {
    start: createTimeOption(startHour, startMinute),
    end: createTimeOption(endHour, endMinute),
    startAMPM,
    endAMPM,
  };
};

export const createTimeOption = (
  hour: number,
  minute: number,
): { value: number; label: string } => {
  const hourIn12Format = hour % 12 === 0 ? 12 : hour % 12;
  const formattedMinute = minute.toString().padStart(2, "0");
  return {
    value: hourIn12Format,
    label: `${hourIn12Format}:${formattedMinute} ${hour >= 12 ? "PM" : "AM"}`,
  };
};
